import { inject } from '@angular/core';
import { CanActivateFn, Router } from "@angular/router";
import { AuthService } from '../services/auth.service';
import { MessageService } from 'primeng/api';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { map } from 'rxjs';

export const userRoleGuard: CanActivateFn = (route, state) => {
  const oidcService: OidcSecurityService = inject(OidcSecurityService);
  const router: Router = inject(Router);
  const messageService: MessageService = inject(MessageService);
  return oidcService.checkAuth()
    .pipe(map((loginResp) => {
      const roles = route.data.roles;
      const userData = loginResp.userData;
      if(!loginResp.isAuthenticated || !loginResp.userData) {
        return false;
      }

      const canActivate = roles.some(r1 => userData.roles.some(r2 => r1 === r2));
      if (canActivate) return canActivate;


      messageService.add({
        severity: 'warn',
        detail: `You are not able to see required page ${state.url}.`,
        summary: 'Access denied',
        life: 3000});

      router.navigate(['']);
      return false

    }));
}

