import {AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild,} from '@angular/core';
import {AbstractControl, FormControl, ValidatorFn, Validators} from '@angular/forms';
import {
  ChatConstants,
  ChatHistoryListModel,
  ChatHistoryModel,
  ChatResponseModel,
  ChatSettings,
  CompanyChatRequestModel,
  CompanyChatResponseModel,
  GenericChatRequestModel,
  GPTVersion,
  MessageRole,
  UserRole,
} from 'digiteq-ai-portal-client-lib';
import {ConfirmationService, MenuItem, MessageService} from 'primeng/api';
import {SettingsService} from '../../services/settings.service';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {AuthService} from '../../services/auth.service';
import {Sidebar} from 'primeng/sidebar';
import {CitationFeModel, FeMessageModel} from '../../models/fe-message.model';
import {ChatType} from '../../models/chat-type';
import {StreamChatService} from "../../services/stream-chat.service";
import {Observable} from 'rxjs';
import {ErrorService} from '../../services/error.service';
import {HttpErrorResponse} from '@angular/common/http';
import {historyCategoryEnum} from '../../models/history-category.enum';
import {get_encoding, Tiktoken} from 'tiktoken';
import _ from 'lodash';

interface ChatHistoryListUiModel {
  items: ChatHistoryListModel[];
  label: string;
}

const CODE_QUOTES = '```';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild("chatContainer") chatContainer: ElementRef<HTMLElement>;
  @ViewChild("historySidebar") historySidebar: Sidebar;
  @ViewChild("settingsSidebar") settingsSidebar: Sidebar;
  @ViewChild("textArea") textArea: ElementRef<HTMLElement>;

  @Input() chatType: ChatType;
  @Input() description: string;

  private _settingsVisible = false;
  get settingsVisible(): boolean {
    return this._settingsVisible;
  }

  set settingsVisible(value: boolean) {
    if (this._settingsVisible !== value) {
      this._settingsVisible = value;
    }
  }

  private _historyVisible = false;
  get historyVisible(): boolean {
    return this._historyVisible;
  }

  set historyVisible(value: boolean) {
    if (this._historyVisible !== value) {
      this._historyVisible = value;
    }
  }

  domObserver: MutationObserver;
  role = MessageRole;

  private _messages: FeMessageModel[] = [];
  get messages(): FeMessageModel[] {
    return this._messages;
  }

  set messages(value: FeMessageModel[]) {
    this._messages = value;
    this.showWelcomeMsg = !value.length;
  }

  messageForm: FormControl;
  aiTypingResponse = false;
  aiResponseError = false;
  enterKeyDown = false;
  isAdvancedUser = false;
  currentChatId: string | undefined;
  currentChatName: string | undefined;
  readonly currentChatNamePlaceholder = 'Your new chat';
  items: MenuItem[] | undefined;
  showWelcomeMsg = true;
  history: ChatHistoryListModel[];
  groupedHistory: ChatHistoryListUiModel[];
  loadingChatHistory: boolean;

  chatTypeEnum = ChatType;
  tokenEncoding: Tiktoken;
  constants: ChatConstants;

  constructor(
    private chatService: StreamChatService,
    private messageService: MessageService,
    private settingsService: SettingsService,
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService,
    private errorService: ErrorService,
    private confirmationService: ConfirmationService
  ) {
    this.tokenEncoding = get_encoding('cl100k_base');
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe(() => {
        // TODO: add ChatCompanyAdvanced
        this.isAdvancedUser = this.authService.isInRoles([UserRole.ChatAdvanced]);
      });

    this.loadChatHistory();
    this.loadConstants();

    this.messageForm = new FormControl('', [
      Validators.required,
      Validators.minLength(3),
      this.notOnlyWhitespace(),
      this.messagesExceedMaxNumberOfToken()
    ]);
  }

  ngAfterViewInit() {
    //pInputTextarea autoresize does not show scrollbar when input is too height
    this.textArea.nativeElement.addEventListener('input', function () {
      this.style.height = 'auto';
      this.style.height = (this.scrollHeight + 5) + 'px';
    });
    this.observeChangesInDom();
  }

  ngOnDestroy() {
    this.domObserver.disconnect();
  }

  private notOnlyWhitespace(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value !== null && control.value.trim() === '') {
        return {'whitespace': true};
      }
      return null;
    };
  }

  private messagesExceedMaxNumberOfToken(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let maxTokens: number;
      if( this.chatType === ChatType.COMPANY){
        maxTokens = this.constants?.maxRequestTokensGpt4oMini
      }
      else {
        switch (this.settingsService.gptVersion){
          case GPTVersion.Gpt35:
            maxTokens = this.constants?.maxRequestTokensGpt35;
            break;
          case GPTVersion.Gpt4:
            maxTokens = this.constants?.maxRequestTokensGpt4;
            break;
          case GPTVersion.Gpt4oMini:
            maxTokens = this.constants?.maxRequestTokensGpt4oMini;
            break;
        }
      }
      if (this.getMessagesTokenLength(control.value) > maxTokens) {
        return {'maxNumOfTokens': true};
      }
      return null;
    };
  }

  addMessage(newMessage: FeMessageModel) {
    const currentMessages = this.messages;
    currentMessages.push(newMessage);
    this.messages = currentMessages;
  }

  stop() {
    if (!this.currentChatId) {
      return;
    }
    this.chatService.stopChat(this.currentChatId).subscribe(() => {
      this.aiTypingResponse = false;
    });
  }

  handleActionButton() {
    if (this.aiTypingResponse) {
      this.stop();
    } else {
      this.send();
    }
  }

  send(resend?: boolean) {
    this.enterKeyDown = true;

    if (!this.messageForm.valid && !resend) return;
    this.enterKeyDown = false;
    this.aiResponseError = false;

    let message = this.messageForm.value;
    if (resend) {
      message = this._messages[this._messages.length - 1].message;
      this._messages.splice(this._messages.length - 1);
    }

    this.addMessage({
      message,
      role: MessageRole.User,
    });

    const settings = this.settingsService.savedSettings;
    const lastMessages = settings?.lastMessages || 5;
    const chat: GenericChatRequestModel | CompanyChatRequestModel = {
      messages: this.messages.slice(-lastMessages),
      settings,
      chatId: this.currentChatId
    };
    this.scrollToBottom();
    this.aiTypingResponse = true;
    this.textArea.nativeElement.style.height = '';

    this.currentChatName = this.currentChatNamePlaceholder;

    this.sendChatMsg(chat);
    this.messageForm.reset();
  }

  sendChatMsg(chat: GenericChatRequestModel | CompanyChatRequestModel) {
    let chatStreamObservable$: Observable<any>;
    switch (this.chatType) {
      case ChatType.GENERIC: {
        chatStreamObservable$ = this.chatService.realGenericChatStream(chat);
      }
        break;
      case ChatType.COMPANY: {
        chatStreamObservable$ = this.chatService.realCompanyChatStream(chat);
      }
        break;
    }

    this.aiTypingResponse = true;
    let firstMessage = true;

    chatStreamObservable$.subscribe({
      next: response => {
        this.handleChatResponse(response, firstMessage);
        firstMessage = false;
        this.messageForm.reset();
      },
      error: err => {
        this.handleChatError(err);
      },
      complete: () => {
        this.aiTypingResponse = false;
        this.messages[this.messages.length - 1].completed = true;
        // TODO: there might be a better way than call API
        this.loadChatHistory();
      },
    });
  }

  private updateCitations(model: FeMessageModel) {
    if (!model.citations) {
      return;
    }

    let citationIndexes: number[] = [];

    const spanRegex = /<sup\s+class=["']citation-in-text["']>([1-5])<\/sup>/g;
    let match;

    // when chatting with OpenAI, after few messages, the engine starts to generate responses now with doc1 etc.,
    // but with formatted span with class "citation-in-text
    while ((match = spanRegex.exec(model.message)) !== null) {
      citationIndexes.push(parseInt(match[1], 10));
    }

    const docRegex = /\[doc(\d+)]/g;

    if (docRegex.test(model.message)) {
      model.message = model.message.replace(docRegex, (substring, idx) => {
        citationIndexes.push(Number(idx));
        return `<sup class="citation-in-text">${idx}</sup>`;
      });
    }

    citationIndexes = [...new Set(citationIndexes)];

    model.citations.filter(c => citationIndexes.includes(c.index))
      .forEach(c => {
        c.used = true;
        c.title = this.decodeHtmlEntities(c.title);
      });
  }

  decodeHtmlEntities(input: string): string {
    const doc = new DOMParser().parseFromString(input, "text/html");
    return doc.documentElement.textContent;
  }

  handleChatResponse(response: ChatResponseModel | CompanyChatResponseModel, first: boolean) {
    const citations: CitationFeModel[] = (response as CompanyChatResponseModel).citations;
    let messagePart = response.text;
    this.updateCitationIndexes(citations);

    if (first) {
      this.currentChatId = response.chatId;
      messagePart = messagePart || '';
      this.messages.push({
        message: messagePart,
        messageBlocks: this.splitMessageToTextAndCode(messagePart),
        citations,
        role: MessageRole.Assistant,
      });
    } else {
      const lastMessage = this.messages[this.messages.length - 1];
      lastMessage.message += messagePart;
      this.updateCitations(lastMessage);
      lastMessage.messageBlocks = this.splitMessageToTextAndCode(lastMessage.message);
    }

    this.aiResponseError = false;
    this.scrollToBottom();

  }

  handleChatError(err: HttpErrorResponse) {
    // remove last assistant's message client already started writing response
    if (this.messages[this.messages.length - 1].role === MessageRole.Assistant) {
      this.messages.splice(-1);
    }
    this.aiTypingResponse = false;
    this.aiResponseError = true;
    this.errorService.handleError(err, 'Could not send your message.')
  }

  scrollToBottom(): void {
    setTimeout(() => {
      this.chatContainer.nativeElement.scrollTo({top: this.chatContainer.nativeElement.scrollHeight});
    });
  }

  reset() {
    this.messages = [];
    this.currentChatName = undefined;
    this.currentChatId = undefined;
  }

  async copyToClipboard(message: string) {
    message = message.replace(/^```|```$/g, '');
    await navigator.clipboard.writeText(message);
    this.messageService.add({
      severity: 'success',
      detail: 'Text copied to clipboard',
      summary: 'Copied!',
      life: 3000,
    });
  }

  getFormattedTextareaValue(text: string): string {
    return text.replace(/\n/g, '<br>');
  }

  splitMessageToTextAndCode(message: string) {
    if (!message) {
      return [];
    }
    const messages = message.split(CODE_QUOTES);
    const newMessages = [];
    messages.forEach((msg, index) => {
      if (index % 2 === 0) {
        newMessages.push(msg);
      } else {
        newMessages.push(CODE_QUOTES + msg + CODE_QUOTES);
      }
    });
    return newMessages;
  }

  observeChangesInDom() {
    const targetNode = document.getElementById('chat-container');
    const callback = (mutationsList: MutationRecord[]) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              const children = Array.from((node as HTMLElement).children);
              for (const child of children) {
                this.makeAnchorTagsTargetBlank(child);
              }
            }
          });
        }
      }
    };
    this.domObserver = new MutationObserver(callback);
    this.domObserver.observe(targetNode, {childList: true, subtree: true});
  }

  makeAnchorTagsTargetBlank(node: Node) {
    if (node.nodeType === Node.ELEMENT_NODE && (node as Element).tagName === 'A') {
      (node as HTMLAnchorElement).setAttribute('target', '_blank');
    } else if (node.hasChildNodes()) {
      const children = Array.from(node.childNodes);
      for (const child of children) {
        this.makeAnchorTagsTargetBlank(child);
      }
    }
  }

  isCodeSnippet(messagePart: string) {
    return messagePart.startsWith(CODE_QUOTES);
  }

  get hasInputValueErrors() {
    return (this.messageForm.hasError('minlength') || this.messageForm.hasError('whitespace'))
      && this.enterKeyDown && this.messageForm.value;
  }

  hasUsedCitation(model: FeMessageModel): boolean {
    return model.citations?.some(c => c.used);
  }

  loadChatHistory() {
    let chatHistoryListObservable$: Observable<ChatHistoryListModel[]>;
    switch (this.chatType) {
      case ChatType.GENERIC: {
        chatHistoryListObservable$ = this.chatService.genericHistoryList();
      }
        break;
      case ChatType.COMPANY: {
        chatHistoryListObservable$ = this.chatService.companyHistoryList();
      }
        break;
    }

    this.loadingChatHistory = true;

    chatHistoryListObservable$.subscribe({
      next: chatHistory => {
        this.history = chatHistory;
        this.historyVisible = this.history.length > 0;
        this.categorizeChatHistory();

        const currentChat = this.history.find(chat => chat.id === this.currentChatId);
        this.currentChatName = currentChat?.name;
        this.loadingChatHistory = false;

        //Re-render settings component so selected GPT version can be reloaded.
        this.reloadSettingsSidebar();
      },
      error: err => {
        this.loadingChatHistory = true;
        this.errorService.handleError(err, 'Could not get chat history.');
      }
    })
  }

  onChatHistoryItemLoad(chat: ChatHistoryModel) {
    this.messageForm.reset();
    this.reset();

    chat.messages.forEach(m => {
      if (m.role == MessageRole.User) {
        this.addMessage(m);
      } else {
        let feMsg: FeMessageModel = {
          message: m.message,
          citations: m.citations,
          role: m.role,
        };
        this.updateCitationIndexes(feMsg.citations);
        this.updateCitations(feMsg);
        feMsg.messageBlocks = this.splitMessageToTextAndCode(feMsg.message);
        this.messages.push(feMsg);
      }
    });

    this.handleLoadedChatSettings(chat.settings);
    this.currentChatId = chat.id;
    this.currentChatName = chat.name;

    this.aiResponseError = false;
    this.scrollToBottom();
  }

  onChatHistoryItemDelete(chatId: string) {
    const index = this.history.findIndex(h => h.id === chatId);
    this.history.splice(index, 1);
    this.categorizeChatHistory();
    if (chatId === this.currentChatId) {
      this.reset();
    }
  }

  onChatRename(renameChatData: { id: string, newName: string }) {
    const historyItem = this.history.find(h => h.id === renameChatData.id);
    historyItem.name = renameChatData.newName;
  }

  handleLoadedChatSettings(newSettings: ChatSettings) {
    if (!newSettings) {
      return;
    }
    if (_.isEqual(newSettings, this.settingsService.savedSettings)) {
      return;
    }
    this.confirmationService.confirm({
      target: null,
      message: 'Do you want to accept settings of the loaded chat?',
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon:"none",
      rejectIcon:"none",
      rejectButtonStyleClass:"p-button-text",
      accept: () => {
        this.settingsService.savedSettings = newSettings;
      },
    });
  }

  categorizeChatHistory() {
    const currentDate = new Date();

    this.groupedHistory = [
      { label: historyCategoryEnum.last7days, items: []},
      { label: historyCategoryEnum.last30days, items: []},
      { label: historyCategoryEnum.older, items: []},
    ]

    this.history.forEach(h => {
      const updatedAt = new Date(h.updatedAt);
      const timeDiff = currentDate.getTime() - updatedAt.getTime();
      const daysDiff = timeDiff / (1000 * 3600 * 24);

      let category = historyCategoryEnum.last7days;

      if (daysDiff > 30) {
        category = historyCategoryEnum.older;
      } else if (daysDiff > 7) {
        category = historyCategoryEnum.last30days;
      }

      const group = this.groupedHistory.find(g => g.label === category);
      group.items.push(h);
    })
  }

  loadConstants() {
    this.settingsService.getConstants().subscribe({
      next: constants => {
        this.constants = constants;
      },
      error: err => {
        this.loadingChatHistory = true;
        this.errorService.handleError(err, 'Could not get chat configuration.');
      }
    });
  }

  getMessagesTokenLength(currentMessage: string) {
    const messagesHistoryString =  this.messages.map(m => m.message).join('');
    const tokens = this.tokenEncoding.encode(currentMessage + messagesHistoryString);
    return tokens.length;
  }

  updateCitationIndexes(citations: CitationFeModel[]) {
    if (citations) {
      citations.forEach((c, index) => {
        c.index = index + 1;
        c.used = false;
      });
    }
  }

  reloadSettingsSidebar() {
    this.settingsSidebar.visible = false;
    setTimeout(() => {
        this.settingsSidebar.visible = true;
    }, 100);
  }

}
