import { Injectable } from '@angular/core';
import { OidcSecurityService, UserDataResult } from 'angular-auth-oidc-client';
import { UserRole } from 'digiteq-ai-portal-client-lib';
import { map, Observable } from 'rxjs';

export interface UserData {
  email?: string;
  name?: string;
  family_name?: string;
  given_name?: string;
  preferred_username?: string;
  sub?: string;
  roles?: UserRole[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUser: UserData;
  isAuthenticated: boolean;

  get currentUser$(): Observable<UserData> {
    return this.oidcService.userData$.pipe(map(r => r.userData as UserData));
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.oidcService.isAuthenticated$.pipe(map(r => r.isAuthenticated));
  }

  constructor(private oidcService: OidcSecurityService) {


    this.oidcService
      .checkAuth()
      .subscribe((loginResponse) => {
          this.isAuthenticated = loginResponse.isAuthenticated;
          if(!loginResponse.isAuthenticated && !loginResponse.errorMessage) {
            this.login();
          }
        });

    oidcService.userData$.subscribe((userDataResult: UserDataResult) => {
        this.currentUser = userDataResult.userData;
    });
  }

  public isInRoles(roles: Array<string>): boolean {
    if (!roles) {
      return true;
    }

    if (this.currentUser?.roles) {
      return roles.some(r1 => this.currentUser.roles.some(r2 => r1 === r2));
    }

    return false;
  }

  login() {
    this.oidcService.authorize();
  }

  logout() {
    this.oidcService
      .logoff()
      .subscribe();
  }
}
